import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from 'src/app/interfaces/article';
import { ApplicationService } from 'src/app/services/application.service';
import { ArticleService } from 'src/app/services/article.service';
import { SearchService } from 'src/app/services/search.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
  selector: "app-recently-viewed",
  templateUrl: "./recently-viewed.component.html",
  styleUrls: ["./recently-viewed.component.scss"],
})
export class RecentlyViewedComponent implements OnInit {
  @Output() noRecent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() maxItems = 6;
  @Input() parent = "home";
  @Input() companyGroupCode = "";
  @Input() cardClass = "col-3";
  articles: Article[] = [];
  constructor(
    private articleService: ArticleService,
    private applicationService: ApplicationService,
    private router: Router,
    private searchService: SearchService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit() {
    this.getArticles();
  }

  getArticles() {
    const articles = this.articleService.getRecentlyViewed();
    if (articles) {
      this.articles = articles.slice(0, this.maxItems);
      this.searchService.getNettPrices(this.articles);

      if (!this.articles.length) {
        this.noRecent.emit(true);
      }
    }
  }

  showArticleDetails(article) {
    // Track click on pdp
    if (this.parent === "pdp") {
      this.trackingService.recentlyArticleClick();
    }

    if (!this.companyGroupCode) {
      this.companyGroupCode =
        this.applicationService.getSelectCompanyGroupCode();
    }

    // redirect to article
    const url = `/${this.companyGroupCode}/articleDetails/${article.id}`;
    if (this.router.url.includes("article")) {
      this.router
        .navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/RefreshComponent`, { skipLocationChange: true })
        .then(() => {
          this.router.navigate([
            url,
          ]);
        });
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
