<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{header}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body">
  <app-ecommerce-articles id="ecommereContainer" [mainArticle]="article.item_details" [type]="'crosssell'" [header]="header" [onlyMandatory]="preorder" [quantityMainArticle]="article.quantity" [basketArticleId]="basketArticleId"
  [showHeader]="false" [modifyFromBasket]="true" (selectForBasket)="replaceMandory($event)" (qtyChanged)="getTotalPreorderQuantity()"></app-ecommerce-articles>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-lg btn-dark" (click)="modal.dismiss('Close')">{{ 'CLOSE' | translate }}</button>
  <button *ngIf="basketArticleId" [ngClass]="maxQuantity !== quantity ? 'disabled btn-default' : 'btn-success'" [disabled]="maxQuantity !== quantity" class=" btn btn-lg"
  (click)="savePreorder()">{{'ADD'| translate}}</button>
</div>
