import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: "app-accentry-logo",
  templateUrl: "./accentry-logo.component.html",
  styleUrls: ["./accentry-logo.component.css"],
})
export class AccentryLogoComponent implements OnInit {
  active: boolean
  customLogo = false;
  customLogoSrc = "";
  showSleigh = false;

  constructor(private applicationService: ApplicationService) { }

  ngOnInit(): void {
    if (this.applicationService.isPartnerPortal()) {
      this.customLogo = true;
      this.customLogoSrc = "assets/Partner_store.png"
    }
    setTimeout(() => {
      this.active = true;
    }, 50)

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 11, 10); // December 10
    const endDate = new Date(currentDate.getFullYear() + 1, 0, 5); // January 5 of the next year
    
    if (currentDate >= startDate && currentDate <= endDate) {
      this.showSleigh = true;
    }

  }
}
