<app-bread-crumbs
  *ngIf="showBreadCrumbs"
  class="position-relative"
  style="z-index: 1;"
  [(breadcrumbs)]="breadcrumbs"
></app-bread-crumbs>

<div
  class="container px-3 mb-3 px-md-2 px-lg-3 px-xl-2" [ngClass]="article?.setitem ? 'setitem' : ''"
  *ngIf="(showDetailed || expanded) && article"
>
  <div class="pt-0 row p-md-2 p-xl-0">
    <div
      class="px-3 pt-0 col-md-12 col-lg-6 col-xl-6 d-sm-block ps-md-2 ps-lg-2 ps-xl-2 position-relative"
    >
      <h1
        *ngIf="article.description"
        [innerHTML]="article.description"
        [ngClass]="article.description.length > 30 ? 'longDesc' : ''"
        class="pt-0 mt-0 mb-0 articleHeader pt-mb-2 d-block d-sm-none mt-sm-1"
      ></h1>
      <h2
        *ngIf="article.description"
        [innerHTML]="
          (article.caption ? article.caption : '') +
          ' ' +
          (article.season && (!['RAL','ANL'].includes(applicationService.getSelectCompanyGroupCode() || framenumber))
            ? article.season
            : '')
        "
        class="pt-0 mb-2 articleHeader subHeader d-block d-sm-none"
      ></h2>

      <h4 class="mb-2 articleHeader">
        <app-focus-model
          *ngIf="article && article.base_collection"
        ></app-focus-model>
      </h4>

      <div
        *ngIf="
          ((!article.parsedImages && placeholder) || article.no_img) &&
          !articleNotFound
        "
        class="articlePreImg d-inline-flex justify-content-center h-100 w-100 mt-4"
        [style.background-image]="'url(' + placeholder + ')'"
        [@enterAnimation]
        style="min-height: 280px"
      >
      <span
      *ngIf="loading"
      class="spinner-border spinner-border"
      role="status"
      aria-hidden="true"
    ></span>
    </div>
      <!-- Article carousel -->
      <app-article-image-carousel
        #carouselContainer
        [@enterAnimation]
        *ngIf="
          article.parsedImages && showCarousel && !article.no_img && !show360
        "
        class="d-flex px-sm-5"
        [height]="article.setitem ? '500px' : '50vh'"
        [(articleImages)]="article.parsedImages"
        [initialImage]="article.f_img[0]"
        [title]="
          article.description + ' ' + (article.caption ? article.caption : '')
        "
      >
      </app-article-image-carousel>
      <app-three-dee-viewer
        *ngIf="article.img3d?.length"
        [id]="'threedeeviewer'"
        [article]="article"
        class="d-flex h-100"
        style="right: 0; top: 0;"
        [ngClass]="{ 'position-absolute': !show360 }"
        [src]="article.img3d[0]"
        (show360)="show360 = $event"
      ></app-three-dee-viewer>

      <div class="text-center" *ngIf="articleNotFound">
        <h1>{{ "ARTICLE_NOT_FOUND" | translate }}</h1>
        <img
          class="w-100"
          src="./assets/images/accident.svg"
          alt="accident"
          style="max-height: 30vh"
        />
      </div>
      <app-disclaimer
        [companyGroups]="['WG', 'GHO']"
        [text]="'DISCLAIMER_IMAGES' | translate"
        [class]="'d-block mx-2 px-1'"
      ></app-disclaimer>
    </div>
    <div class="pb-0 col-md-12 col-lg-6 col-xl-6 h-100 articleBasics">
      <h1
        *ngIf="article.description"
        [innerHTML]="article.description"
        [ngClass]="article.description.length > 30 ? 'longDesc' : ''"
        class="pt-0 px-2 mb-0 articleHeader pt-mb-2 d-none d-sm-block"
      ></h1>
      <h2
        *ngIf="article.description"
        [@enterAnimation]
        [innerHTML]="
          (article.caption ? article.caption : '') +
          ' ' +
          (article.season &&
          (!['RAL','ANL'].includes(applicationService.getSelectCompanyGroupCode()) || framenumber)
            ? article.season
            : '')
        "
        class="pt-0 px-2 mb-2 articleHeader subHeader d-none d-sm-block"
      ></h2>
      <div
        class="textContainer customScrollbar mt-4 px-2"
        [ngClass]="!article.has_replacement ? 'maxHeight' : ''"
      >
        <p
          *ngIf="!article.has_replacement"
          [innerHTML]="article.text ? article.text : ''"
          class="mb-0"
          [@enterAnimation]
        ></p>
        <app-replacement-article
          *ngIf="article.has_replacement && article.replacement_article"
          [articleCode]="article.replacement_article['article_code']"
        ></app-replacement-article>
        <div [innerHTML]="article.usps ? article.usps : ''" [@enterAnimation] *ngIf="article.usps"></div>
        <b *ngIf="!article.season && article.brand">{{ article.brand }}</b>
        <p
          [style.visibility]="
            article.single_article_code ? 'visible' : 'hidden'
          "
        >
          {{ "ARTICLE_NO" | translate }}: {{ article.single_article_code }}
          <br />
          <ng-container *ngIf="article.single_article_code_short && article.single_article_code !== article.single_article_code_short"
            >{{ "SECONDARY_ARTICLENR" | translate }}:
            {{ article.single_article_code_short }}<br
          /></ng-container>
          <ng-container *ngIf="article.single_ean"
            >{{ "EANCODE" | translate }}: {{ article.single_ean }}</ng-container
          >
          <ng-container *ngIf="article.supplier_code"
            ><br />{{ "SUPPLIER_CODE" | translate }}:
            {{ article.supplier_code }}</ng-container
          >
          <ng-container *ngIf="article.store_packaging"
          ><br />{{article.store_packaging.description}}:
          {{article.store_packaging.value}}</ng-container
        >
        </p>
        <p
          *ngIf="hasDealerSupportArticles && article.mandatory_battery"
          class="border border-warning statusIcon status status_3"
        >
          {{ "CALL_DEALERSUPPORT" | translate }}
        </p>
        <ng-content></ng-content>
      </div>

      <div
        class="addContainer w-100"
        [style.display]="articleNotFound  ? 'none' : ''"
      >
      <div class="px-2">
        <!-- Article matrix -->
        <div class="mb-3">
          <ng-container *ngIf="article.matrix">
            <app-matrix
              [article]="article"
              *ngIf="
                (article.matrix && article.matrix.options.length > 1) ||
                  (article.matrix &&
                    article.matrix.options.length === 1 &&
                    article.matrix.options[0].value.length > 1);
                else stockStatus
              "
              (articleSelected)="
                selectArticle($event);
              "
            ></app-matrix>
          </ng-container>
          <ng-template #stockStatus>
            <span *ngIf="article.temporarily_undeliverable || modelTemporarilyUndeliverable" class="d-block text-danger w-100">
              {{"TEMPORARILY_UNDELIVERABLE"|translate}}
            </span>
            <app-stock-status
              [showText]="true"
              *ngIf="
                article.stock_status <= 7 &&
                (!article.matrix || article.matrix.options.length <= 1) && !modelTemporarilyUndeliverable
              "
              [article]="article"
            ></app-stock-status>
          </ng-template>
        </div>

        <!-- Article prices -->
        <div class="priceContainer">
          <h5 *ngIf="article.retail_price" class="mb-0">
            <app-retail-price
              [price]="article.retail_price"
              [showLabel]="true"
            ></app-retail-price>
          </h5>
          <h4 *ngIf="article.gross_price" class="mb-0 d-block">
            <app-gross-price
              [price]="article.gross_price"
              class="opacity-75"
              [showLabel]="true"
            ></app-gross-price>
          </h4>
          <h5 *ngIf="article.lease_start_price" class="mb-2 d-block">
            <app-lease-price
              *ngIf="
                article.lease_start_price && article.lease_start_price.value && article.is_lease
              "
               class="opacity-75"
              [showTable]="!article.has_flex_batt"
              [lowestPrice]="article.lease_start_price"
              [articleCode]="article.single_article_code"
              [leasePrices]="article.lease_price"
            ></app-lease-price>
          </h5>
          <h3
            *ngIf="article.net_price"
            class="mb-4 mb-md-3 mt-1"
          >
            <app-nett-price
              [price]="article.net_price"
              [showLabel]="true"
              [hasDifferentPrices]="article.has_different_prices"
              [volumePrices]="article.volume_price"
              [article]="article"
            ></app-nett-price>
          </h3>
          <div *ngIf="article.volume_price" class="d-inline-block">
            <app-volume-prices
              [headerLeft]="'QUANTITY'"
              [headerRight]="'PRICE'"
              [popoverTitle]="'VOLUME_PRICES'"
              [price]="article.volume_price"
              [regularPrice]="article.net_price"
            ></app-volume-prices>
          </div>
        </div>
        <app-highlight-badge
          [article]="article"
          class="relative"
          *ngIf="
            article.promotion ||
            article.new ||
            article.setitem ||
            article.mandatory_battery ||
            article.is_lease || article.collection_status
          "
        >
        </app-highlight-badge>
      </div>

        <div class="row addButtonContainer">
          <div class="col-md-6 col-lg-12 col-xl-8 col-sm-6 col-xs-6 centerContainer ps-sm-4" [style.display]="preOrder ? 'none' : ''">
            <app-add-to-basket-button
              *ngIf="!article.setitem && !selectForWarrantyMode"
              [article]="article"
              class="d-inline-block"
              [ngClass]="article.bom ? 'hasBom' : ''"
            >
              <button
                class="ms-1 me-0 btn btn-dark btn-lg me-md-0 ms-sm-0"
                *ngIf="article.bom && showBom" pRipple
                (click)="links.showBom($event)"
              >
                {{ "BOM" | translate }}
              </button>
              <app-add-to-lease-button
                class="ms-2 d-block"
                [article]="article"
                [fromLeaseRequest]="leaseRequest"
                *ngIf="article.is_lease && article.article_type === 'F'"
                [disabled]="!article.single_article_code"
              ></app-add-to-lease-button>
            </app-add-to-basket-button>

            <app-select-for-warranty-button
              [article]="article"
              [bomQuantity]="bomQuantity"
              *ngIf="selectForWarrantyMode"
            >
            </app-select-for-warranty-button>
          </div>
          <div
            class="d-xl-flex align-items-xl-center"
            [class]="!preOrder ? 'px-0 col-md-6 col-lg-12 col-xl-4 col-sm-6 col-xs-6 centerContainer mt-2 mt-sm-0 ps-3' : 'col-12'"
            [class.justify-content-xl-end]="!preOrder"
            [hidden]="hideLinks"
          >
            <!-- Article links -->
            <app-article-links
              #links
              class="d-flex mt-0 me-2 mt-sm-4 mt-md-3 mt-xl-0"
              [class.w-100]="preOrder"
              [article]="article"
              [hidden]="article.stock_status === 7"
              [preorder]="preOrder"
              [articleDetails]="true"
            ></app-article-links>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="other-details mt-5" *ngIf="(showDetailed || expanded) && article">
  <div class="container">
    <div class="px-0 col-md-12">
      <app-set-items
        [article]="article"
        [showDetailed]="true"
        [companyId]="article.company_id"
        class="mt-2 w-100 d-block"
      >
      </app-set-items>
    </div>

    <div class="row">
      <div
        class="col-md-12 col-lg-6 col-sm-12"
        *ngIf="
          article.alternative_ecommerce ||
          article.cross_sell_ecommerce ||
          article.up_sell_ecommerce ||
          article.has_flex_batt
        "
      >
        <!-- E-commerce -->
        <ul class="mb-0 border-0 nav nav-pills my-md-4 mb-md-2">
          <li class="nav-item" *ngIf="article.alternative_ecommerce">
            <h3
              class="mb-0 clickable withOpacity"
              (click)="selectedEcommerceTab = 'alternatives'"
              [ngClass]="
                selectedEcommerceTab === 'alternatives' ? 'active' : ''
              "
            >
              {{ "ALTERNATIVES" | translate }}
            </h3>
          </li>
          <li
            class="nav-item"
            *ngIf="article.cross_sell_ecommerce || article.has_flex_batt"
          >
            <h3
              class="mb-0 clickable withOpacity"
              (click)="selectedEcommerceTab = 'crosssell'"
              [ngClass]="[selectedEcommerceTab === 'crosssell' ? 'active' : '']"
            >
              {{ "CROSS_SELL_HEADER" | translate }}
            </h3>
          </li>
        </ul>
        <div
          *ngIf="
            article.alternative_ecommerce &&
            selectedEcommerceTab === 'alternatives'
          "
        >
          <app-ecommerce-articles
            #alternatives
            [mainArticle]="article"
            [type]="'alternatives'"
            [header]="'ALTERNATIVES' | translate"
            [showHeader]="false"
            [shopOption]="false"
          ></app-ecommerce-articles>
        </div>
        <div
          *ngIf="
            ((article.cross_sell_ecommerce || article.has_flex_batt) &&
              selectedEcommerceTab === 'crosssell') ||
            (!article.alternative_ecommerce &&
              (article.cross_sell_ecommerce || article.has_flex_batt))
          "
        >
          <app-ecommerce-articles
            [mainArticle]="article"
            [type]="'crosssell'"
            [header]="'CROSS_SELL' | translate"
            [showHeader]="false"
            [shopOption]="false"
            (hasDealerSupportArticles)="hasDealerSupportArticles = $event"
          ></app-ecommerce-articles>
        </div>
        <div
          *ngIf="
            (article.up_sell_ecommerce)
          "
        ><h3
        class="mb-0 clickable withOpacity"
        (click)="selectedEcommerceTab = 'upsell'"
        [ngClass]="[selectedEcommerceTab === 'upsell' ? 'active' : '']"
        >
          {{ "UPSELL_SELL_HEADER" | translate }}
        </h3>
          <app-ecommerce-articles
            [mainArticle]="article"
            [type]="'upsell'"
            [header]="'UP_SELL' | translate"
            [showHeader]="false"
            [shopOption]="false"
          ></app-ecommerce-articles>
        </div>
      </div>
      <div
        class="col-md-12 col-sm-12"
        [ngClass]="
          article.alternative_ecommerce ||
          article.cross_sell_ecommerce ||
          article.has_flex_batt
            ? 'col-md-6 col-lg-6 '
            : 'col-md-12 col-lg-12'
        "
      >

        <h3
          class="pt-4 ps-1"
          *ngIf="article.parsedSpecs && article.parsedSpecs.length"
        >
          {{ "SPECIFICATIONS" | translate }}
        </h3>
        <div class="row mx-0">

          <app-article-specifications
            class="w-100 d-block ps-1"
            #specifications
            [showDetailed]="showDetailed || expanded"
            [specifications]="article.parsedSpecs"
            [ngClass]="
              specifications.specsCollapsed ? 'collapsed' : specsCollapsable ? 'expanded' : ''
            "
            [companyId]="article.company_id"
            [specsCollapsed]="specifications.specsCollapsed"
          ></app-article-specifications>
        </div>

      </div>
      <app-cms-page-content
      *ngIf="article.model_code"
      [pageId]="'article-information-' + (article.model_code | lowercase) + '?folder=companyPage/article-data'"
      [showNotFound]="false"
      (contentLoaded)="specifications.specsCollapsed = $event; specifications.hasBeenCollapsed = $event"
      ></app-cms-page-content>
    </div>

    <app-recently-viewed
      id="recentlyViewed"
      #recentlyViewed
      *ngIf="!preOrder"
      [hidden]="!showRecent || loading"
      [companyGroupCode]="applicationService.getSelectCompanyGroupCode()"
      [maxItems]="4"
      [parent]="'pdp'"
    ></app-recently-viewed>

  </div>
</div>
<!--Compact view for treeview -->
<div
  class="container-compact compact customScrollbar"
  *ngIf="!showDetailed && !expanded"
>
  <div class="ps-4 pb-4 row w-100">
    <div class="ps-0 col-md-12 d-sm-block">
      <h1
        [innerHTML]="article.description"
        class="mb-0 articleHeader"
        (click)="gotoItem(article, $event)"
      ></h1>
      <h2
        [innerHTML]="
          (article.caption ? article.caption : '') +
          ' ' +
          (article.single_article_code ? article.single_article_code : '') +
          (article.single_ean ? ' | ' + article.single_ean : '')
        "
        (click)="gotoItem(article, $event)"
      ></h2>
      <h4 class="mb-2 articleHeader">
        <app-focus-model *ngIf="article.base_collection"></app-focus-model>
      </h4>
      <div
        *ngIf="(!article.parsedImages && placeholder) || article.no_img"
        class="text-center w-100 imgContainer"
      >
        <img
          [src]="article.thumbs[0]"
          *ngIf="article.thumbs && article.thumbs.length"
          class="img-responsive"
        />
      </div>
      <app-article-image-carousel
        #carouselContainer
        *ngIf="article.parsedImages && showCarousel && !article.no_img"
        [height]="'240px'"
        [(articleImages)]="article.parsedImages"
        [title]="
          article.description + ' ' + (article.caption ? article.caption : '')
        "
      ></app-article-image-carousel>
      <app-highlight-badge
        [article]="article"
        (click)="gotoItem(article, $event)"
        *ngIf="article.stock_status < 5 || article.setitem || article.is_lease"
      ></app-highlight-badge>
    </div>
    <div class="ps-0 col-md-12">
      <div class="my-3">
        <div class="mb-3">
          <ng-container *ngIf="article.matrix">
            <app-matrix
              [article]="article"
              *ngIf="article.matrix && article.matrix.options.length > 1"
              (articleSelected)="selectArticle($event)"
            ></app-matrix>
          </ng-container>
          <app-inline-matrix
            [article]="article"
            *ngIf="
              (article.matrix &&
                (!article.matrix.options.length ||
                  article.matrix.options.length === 1)) ||
              !article.matrix
            "
          >
          </app-inline-matrix>
        </div>
        <h5 *ngIf="article.retail_price">
          <app-retail-price
            [price]="article.retail_price"
            [showLabel]="true"
          ></app-retail-price>
        </h5>
        <h4 *ngIf="article.gross_price" class="mb-0 d-block">
          <app-gross-price
            [price]="article.gross_price"
            [showLabel]="true"
          ></app-gross-price>
        </h4>
        <h4 *ngIf="article.lease_start_price" class="my-2 d-block">
          <app-lease-price
            *ngIf="article.lease_start_price && article.lease_start_price.value && article.is_lease"
            [showTable]="!article.has_flex_batt"
            [lowestPrice]="article.lease_start_price"
            [articleCode]="article.single_article_code"
            [leasePrices]="article.lease_price"
          ></app-lease-price>
        </h4>
        <h3
          *ngIf="article.net_price"
        >
          <app-nett-price
            [price]="article.net_price"
            [showLabel]="true"
            [hasDifferentPrices]="article.has_different_prices"
            [volumePrices]="article.volume_price"
            [article]="article"
          ></app-nett-price>
        </h3>
        <div
          *ngIf="article.volume_price && article.volume_price"
          class="d-inline-block"
        >
          <app-volume-prices
            [headerLeft]="'QUANTITY'"
            [headerRight]="'PRICE'"
            [popoverTitle]="'VOLUME_PRICES'"
            [price]="article.volume_price"
            [regularPrice]="article.net_price"
          ></app-volume-prices>
        </div>

        <app-add-to-basket-button
          *ngIf="!article.setitem && !selectForWarrantyMode"
          [article]="article"
          class="mt-2 d-block"
        >
          <button
            class="btn btn-dark mt-lg-1 mt-xl-0" pRipple
            *ngIf="article.bom && showBom"
            (click)="links.showBom($event)"
          >
            {{ "BOM" | translate }}
          </button>
          <app-add-to-lease-button
            class="d-flex"
            [btnClass]="' ms-2 mt-lg-1 mt-xl-0'"
            [article]="article"
            *ngIf="article.is_lease && article.article_type === 'F'"
            [fromLeaseRequest]="leaseRequest"
            [disabled]="!article.single_article_code"
          ></app-add-to-lease-button>
        </app-add-to-basket-button>
        <app-select-for-warranty-button
          *ngIf="selectForWarrantyMode"
          [article]="article"
          [bomQuantity]="bomQuantity"
        >
        </app-select-for-warranty-button>
      </div>
      <p
        (click)="textCollapsed = !textCollapsed"
        [innerHTML]="article.text ? article.text : ''"
      ></p>
      <app-replacement-article
        *ngIf="article.has_replacement && article.replacement_article"
        [articleCode]="article.replacement_article['article_code']"
      ></app-replacement-article>
      <div [innerHTML]="article.usps ? article.usps : ''"></div>
    </div>
    <div class="px-0 col-md-12">
      <app-set-items
        [article]="article"
        [showDetailed]="false"
        [companyId]="article.company_id"
        *ngIf="article.single_article_code && showDetailed"
        class="mt-2 w-100 d-block"
      ></app-set-items>
    </div>
    <div class="px-3 row">
      <app-article-specifications
        [showDetailed]="showDetailed"
        [specifications]="article.parsedSpecs"
      >
      </app-article-specifications>
      <div class="col-md-12">
        <app-article-links
          #links
          class="float-left mt-2"
          [article]="article"
          [hidden]="article.stock_status === 7"
        ></app-article-links>
      </div>
    </div>
  </div>
</div>
