import { Component } from '@angular/core';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

@Component({
  selector: 'app-sleigh',
  templateUrl: './sleigh.component.html',
  styleUrl: './sleigh.component.scss'
})
export class SleighComponent {

  visible = false;

  ngOnInit(): void {
    setInterval(() => {
      this.startAnimation();
    }, 2 * 10 * 1000); // Every 2 minutes
  }

  startAnimation(): void {
    this.visible = true;
    gsap.to('#sleigh', {
      duration: 5,
      motionPath: {
        path: '#sleighPath',
        align: '#sleighPath',
        alignOrigin: [0.5, 0.5]
      },
      opacity: 1, // Start fully visible
      repeat: 0,
      onStart: () => {
        gsap.to('#sleigh', { delay: 1.5, duration: 4, opacity: 0 }); // Fade out effect at the last moment
      },
      onComplete: () => {
        gsap.set('#sleigh', { opacity: 0 }); // Set opacity to zero when done
      }
    });
  }
}
