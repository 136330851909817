<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "CAUTION" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="articlesInBackorderDetails.length">
    <ngb-alert [dismissible]="false">{{
      "ARTICLES_IN_BACKORDER_ALERT" | translate
    }}</ngb-alert>
    <table class="table list-group">
      <ng-container *ngFor="let article of articlesInBackorderDetails">
        <tr
          class="px-0 py-2 list-group-item d-flex"
          *ngIf="article &&
            articlesInBackorder[article.single_article_code]
              .quantity_in_backorder > 0
          "
        >
          <td class="px-0 text-center" style="width: 10%">
            <app-article-image
              [imageUrl]="article.thumbs[0]"
              [articleType]="article.article_type"
              [useLazyLoad]="false"
              [description]="article.description"
              [classList]="'img-thumbnail'"
            ></app-article-image>
          </td>
          <td style="width: 60%">
            {{ article.single_article_code }} |
            <span [innerHTML]="article.description"></span>
            <span class="d-block" [innerHTML]="article.caption"></span>
            <span class="d-block"
              >{{ "QTY_IN_BACKORDER" | translate }}:
              {{
                articlesInBackorder[article.single_article_code]
                  .quantity_in_backorder
              }}
            </span>
          </td>
          <td style="width: 30%" class="d-flex justify-content-end">
            <span class="form-check form-switch">
              <i
                class=" material-icons clickable withOpacity"
                style="opacity: 1"
                (click)="deleteArticle(article)"
                *ngIf="!article.error"
                >delete_forever</i
              >
              <i class="text-danger" *ngIf="article.error">{{
                "ERROR" | translate
              }}</i>
            </span>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
  <ng-container *ngIf="articles.length">
    <ngb-alert [dismissible]="false" class="mt-2">{{
      "BACKORDER_ALERT" | translate
    }}</ngb-alert>
    <table class="table list-group">
      <tr *ngFor="let article of articles" class="px-0 py-2 list-group-item d-flex">
        <td class="px-0 text-center" style="width: 10%">
          <app-article-image
            [imageUrl]="article.item_details.thumbs[0]"
            [articleType]="article.item_details.article_type"
            [useLazyLoad]="false"
            [description]="article.item_details.description"
            [classList]="'img-thumbnail'"
          ></app-article-image>
        </td>
        <td style="width: 60%">
          {{ article.item_details.single_article_code }} |
          <span [innerHTML]="article.item_details.description" class="px-0"></span>
          <span
            class="d-block px-0"
            [innerHTML]="article.item_details.caption"
          ></span>
          <span class="px-0">{{ "NUMBER" | translate }}: {{ article.quantity }}</span>
        </td>
        <td style="width: 30%" class="d-flex justify-content-end">
          <span class="form-check form-switch">
            <input
              type="checkbox"
              class="form-check-input"
              [ngClass]="'state-' + (article.backorder ? 'active' : 'inActive')"
              [id]="'dummy_back_order_' + article.id"
              (change)="articleSwitchChanged(article, article.backorder, 'backorder')"
              [(ngModel)]="article.backorder"
            />
            <label
              class="form-check-label"
              [for]="'dummy_back_order_' + article.id"
              >{{ "BACKORDER_HEADER" | translate }}</label
            >
          </span>
        </td>
      </tr>
    </table>
  </ng-container>
  <!-- <div class="col-12">
    <div class="d-flex">
      <app-deliver-when-all-in-stock [id]="'deliver_when_all_backorderalert'" *ngIf="showDeliverAll && applicationService.getSelectCompanyGroupCode() !== 'WG'" (changed)="updateDelivery($event)" [state]="basket.complete_delivery_only"></app-deliver-when-all-in-stock>
    </div>
  </div> -->
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-lg btn-dark"
    (click)="modal.dismiss('Close')"
  >
    {{ "CLOSE" | translate }}
  </button>
  <button class=" btn btn-lg btn-success" [disabled]="loading" (click)="submit()">
    {{ "NEXT" | translate }}
  </button>
</div>
