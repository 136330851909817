import { Component, Renderer2 } from '@angular/core';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import moment from 'moment';
import { IndexStatus } from 'src/app/interfaces/admintool/index-status';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { AccountService } from 'src/app/services/account.service';
import { AdminService } from 'src/app/services/admin.service';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { HelperService } from 'src/app/services/helper.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-test-settings',
  templateUrl: './test-settings.component.html',
  styleUrls: ['./test-settings.component.scss'],
})
export class TestSettingsComponent {
  v2basket = false;
  basketFrequency = false;
  isPartnerPortal = this.applicationService.isPartnerPortal();
  storeName: string = 'default';
  indexData: IndexStatus = null;
  isAdmin: boolean;
  tabIndex: any;
  lastRun: string;
  constructor(
    private applicationService: ApplicationService,
    private adminService: AdminService,
    private helperService: HelperService,
    private authenticateService: AuthenticateService
  ) {}

  ngOnInit() {
    this.storeName = localStorage.getItem("storeName");
    this.basketFrequency = localStorage.getItem("feature_BasketFrequency") !== null ? true : false;
    this.isAdmin = this.authenticateService.isAdmin();
    if (this.applicationService.isV2Basket()) {
      this.v2basket = true;
    }
  }

  saveSettings(reload?: boolean) {
    if (!this.v2basket) {
      localStorage.removeItem('feature_BasketsV2');
    } else {
      localStorage.setItem('feature_BasketsV2', '1');
    }

    if (!this.basketFrequency) {
      localStorage.removeItem('feature_BasketFrequency');
    } else {
      localStorage.setItem('feature_BasketFrequency', '1');
    }

    // Reload to use the settings
    if (reload) {
      window.location.reload();
    }
  }

  setStore() {
    localStorage.setItem("storeName", this.storeName);
    window.location.reload();
  }

  open(data: any) {
    this.tabIndex = data['index'];
    if (data['index'] === 1) {
      this.adminService.getIndexStatus().subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.indexData = apiResponse.result;
          this.lastRun = moment(this.indexData.pim_parser_last_run).format("DD-MM-YYYY HH:mm:ss")
        }
      })
    }
  }

  close(data: any) {
    this.tabIndex = -1;
  }
}
