import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasketService } from 'src/app/services/basket.service';
import { BasketArticle } from 'src/app/interfaces/basketArticle';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { PreorderService } from 'src/app/services/preorder.service';
import { PreorderInputCollection } from 'src/app/interfaces/preorder/preorder-input-collection';
import { PreorderSelectedArticle } from 'src/app/interfaces/preorder/preorder-selected-article';
import { ApplicationService } from 'src/app/services/application.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mandatory-select-modal',
  templateUrl: './mandatory-select-modal.component.html',
  styleUrls: ['./mandatory-select-modal.component.scss']
})
export class MandatorySelectModalComponent implements OnInit {
  @Input() basketId: number;
  @Input() basketArticleId: number;
  @Input() header: string;
  @Input() article: BasketArticle;
  @Input() preorder: boolean;
  @Input() maxQuantity: number;
  @ViewChild("ecommereContainer") el: ElementRef;
  quantity: number;
  constructor(public modal: NgbActiveModal, private basketService: BasketService, private preorderService: PreorderService, private applicationService: ApplicationService, private alertService: AlertService, private translateService: TranslateService) { }

  ngOnInit() {
  }

  replaceMandory(article: Article) {
    const basketArticle = new BasketArticle();
    basketArticle.single_article_code = article.single_article_code;
    basketArticle.quantity = 1;
    basketArticle.delivery_week = `${String(this.article.delivery_year ?? this.article.year)}${String(this.article.delivery_week ?? this.article.week)}`;
    basketArticle.parent_basket_item_id = this.basketArticleId;
    basketArticle.parent_id = this.basketArticleId;
    this.basketService.addArticlesToBasket([basketArticle], this.basketId).then((apiResponse: ApiResponse) => {
      this.modal.close();
    })
  }

  getTotalPreorderQuantity() {
    let totalQty = 0;
    let parent = document.getElementsByTagName("app-ecommerce-articles")[0];
    let lists = parent.getElementsByTagName("app-article-weeks-table");
    for (let i = 0; i < lists.length; i++) {
      const preorderInputCollection = this.preorderService.collectInput(
        lists[i]
      );
      totalQty += preorderInputCollection.totalQuantity;
    }

    this.quantity = totalQty;
  }

  private getMainArticleSelection() {
    const parent = document.getElementById("ecommereContainer");
    const articles: PreorderInputCollection = this.preorderService.collectInput(parent, this.basketArticleId);

    return articles["articleList"];
  }

  // Add selected mandatory items + main item to the basket
  savePreorder() {
    this.applicationService.showLoader(true);
    this.basketService.addArticlesToBasket(this.getMainArticleSelection()).then((response: ApiResponse) => {
      this.applicationService.hideLoader();
      this.modal.dismiss();
      if (response.success) {
        this.alertService.showInfoNotification(response.result);
      } else {
        this.alertService.showDangerNotification(
          this.translateService.instant(response.result)
        );
      }
    });
  }
}
