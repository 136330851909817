import { Component, OnInit } from "@angular/core";
import { BasketService } from "src/app/services/basket.service";
import { NgbDropdownConfig, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { GroupedBasketArticles } from "src/app/interfaces/grouped-basket-articles";
import { Basket } from "src/app/interfaces/basket";
import { ApiResponse } from "src/app/interfaces/api-response";
import { DeviceDetectorService } from "ngx-device-detector";
import { timer } from "rxjs";
import { HelperService } from "src/app/services/helper.service";
import { AccountService } from "src/app/services/account.service";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { BasketAction } from "src/app/interfaces/basket-action";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: 'app-basket-bar',
  templateUrl: './basket-bar.component.html',
  styleUrl: './basket-bar.component.scss',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
    ])
  ]
})
export class BasketBarComponent {
  numArticles = 0;
  articles: any;
  basket: Basket;
  selectedCompanyGroup: string;
  changing: boolean;
  loading = false;
  groupedArticles: GroupedBasketArticles;
  timer: any;
  basketSum: any = {
    parts: 0,
    bicycles: 0,
    total: 0
  };
  showroomMode = false;
  adding: boolean;
  imgSrc: any;
  addedArticles: any;
  dropdown: any;
  isMobile: boolean;
  cursorOutModal: boolean;
  isDealer: boolean;
  collapseParts = false;
  collapseBicycles = false;

  constructor(
    private basketService: BasketService,
    config: NgbDropdownConfig,
    private applicationService: ApplicationService,
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private helperService: HelperService,
  ) {
    this.isMobile = this.deviceDetector.isMobile();
    this.isDealer = !this.applicationService.isPartnerPortal();
    this.basketService.changeEmitted$.subscribe(response => {
      switch (response["type"]) {
        case "deleteArticle":
          this.deleteArticle(response);
          this.getActiveBasket();
          break;

        case "deleteBasket":
        case "addArticle":
          this.addedArticles = [];
          this.getActiveBasket();
          if (response["type"] === "addArticle") {
            if (response["articles"] && response["articles"].length) {
              const articles = response["articles"];
              this.addedArticles = articles;
              this.adding = true;
              timer(articles.length * 3000 + (articles.length * 250)).subscribe(() => {
                this.adding = false;
              })
            }
          }
          break;
        case "updateArticle":
          if (!response["fromBasketBar"]) {
            this.getActiveBasket();
          }
          break;

        case "unlocked":
          if (this.basket && this.basket.locked) {
            this.basket.locked = false
          }
          break;

        default:
          if (response["type"] !== "totalChange" && response["type"] !== "updateCheckoutStatusArticle") {
            this.getActiveBasket();
          }
          break;
      }
    });
    config.autoClose = true;
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  showUpdateAnimation() {
    this.changing = true;
    setTimeout(() => {
      this.changing = false;
    }, 400);
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
      if (this.basket && this.basket) {
        this.calcTotal(this.basket.items);
      }
    });

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      response => {
        this.basket = null;
        this.selectedCompanyGroup = this.applicationService.getSelectCompanyGroupCode();
      }
    );

    this.selectedCompanyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.getActiveBasket();
  }

  calcTotal(articles) {
    if (this.basket) {
      this.basketSum = this.basketService.calcTotal(
        articles,
        "basketPreview",
        this.showroomMode,
        this.basket.shipping_cost
      );
    }
  }

  getActiveBasket() {
    this.loading = true;
    const service = this.basketService.getActiveBasket();
    service.then((responseData: ApiResponse) => {
      this.loading = false;
      if (this.helperService.checkResponse(responseData)) {
        // Show change in basket article count
        this.numArticles = responseData.result["items"].length;

        this.basket = responseData.result;

        this.articles = responseData.result["items"];
        this.groupedArticles = this.basketService.groupArticles(
          this.articles, true
        );
        this.calcTotal(this.articles);
      } else {
        this.numArticles = 0;
        this.articles = [];
        this.groupedArticles = new GroupedBasketArticles();
      }
    });
  }

  showDropdown(dropdown) {
    if (!window.location.href.endsWith("basket")) {
      this.dropdown = dropdown;
      this.getActiveBasket();

      this.timer = setTimeout(() => {
        if (!window.location.href.endsWith("basket")) {
          dropdown.open();
        }
      }, 500);
    }
  }

  hideDropdown(dropdown = null) {
    if (dropdown) {
      dropdown.close();
    }
    if (this.dropdown) {
      this.dropdown.close();
    }
    clearTimeout(this.timer);
  }

  // Only close the dropdown when there is no article modal active
  closeDropdown(dropdown: NgbDropdown, direct = false) {
    const articleModal = document.getElementById("modalContainer").getElementsByTagName("app-article-modal");
    if (!articleModal.length) {

      // If cursor outside window - set timeout - to prevent accidental closing
      this.cursorOutModal = true;

      if (direct) {
        dropdown.close();
      } else {
        timer(300).subscribe(() => {
          if (this.cursorOutModal) {
            dropdown.close();
          }
        })
      }
    }
  }

  gotoBasket(dropdown: NgbDropdown) {
    if (this.deviceDetector.isDesktop()) {
      this.basketService.cancelCall();
    }
    this.loading = false;
    if (typeof dropdown !== "undefined" && this.deviceDetector.isDesktop()) {
      dropdown.close();
      this.router.navigateByUrl(this.selectedCompanyGroup + "/basket");
    } else {
      dropdown.open();
    }
  }

  unlockBasket() {
    this.basketService.unlockBasket(this.basket.id)
  }

  stopTimer(dropdown: NgbDropdown) {
    if (!this.dropdown?.isOpen()) {
      clearTimeout(this.timer);
    }
  }

  onQuantityChange(article: BasketArticle) {
    this.basketService.updateArticle(this.basket.id, article)
    .subscribe((response: ApiResponse) => {

      this.loading = false;

      // Trigger update
        this.basketService.emitChange({
        type: "updateArticle",
        basketId: this.basket.id,
        basketArticleId: article.id,
        fromBasketBar: true,
        article: article,
      });
    });
  }

  deleteArticle(article: BasketArticle) {
    this.applicationService.showLoader(true);
    this.basketService
    .deleteArticle(this.basket.id, article)
    .subscribe((response: ApiResponse) => {
      this.applicationService.hideLoader();
      if (response.success && response.result) {
        const basketAction = new BasketAction();
        basketAction.type = "deleteArticle";
        basketAction.article = article;
        basketAction.basketId = this.basket.id;
        basketAction.basketArticleId = article.id;
        this.basketService.emitChange(basketAction);
      }
    });
  }

  gotoItem(article: BasketArticle) {
    const url =
    this.applicationService.getSelectCompanyGroupCode() +
    '/articleDetails/' +
    article.item_details.id +
    '/' +
    (article.article_code ? article.article_code : '')
    this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/RefreshComponent`, { skipLocationChange: true })
          .then(() => {
            this.router.navigate([url]);
          });
  }
}
