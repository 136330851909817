<div *ngIf="selectedShippingOverview" class="mt-2 mb-2 opacity-75">
  <b class="d-block text-start">{{type|translate}}</b>
  <ng-container *ngFor="let shippingCost of shippingCosts">
    <b>{{shippingCost.shipping_address?.name}} {{shippingCost.shipping_address?.city}}</b>
    <div *ngIf="shippingCost.shipping_costs?.value" class="d-block text-start mb-2">{{'MIN_ORDER_AMOUNT_NOSHIPPING'|translate}} <span [innerHTML]="shippingCost.shipping_threshold?.threshold?.value|currencyConvert:shippingCost.shipping_threshold?.threshold?.currency"></span></div>
    <table class="table table-striped mb-0">
      <tr *ngIf="shippingCost.shipping_costs?.value">
        <td class="text-end">{{'SHIPPINGCOST'|translate}}:</td>
        <td class="text-nowrap"
          [innerHTML]="shippingCost.shipping_costs?.value|currencyConvert:shippingCost.shipping_costs?.currency"></td>
      </tr>
      <tr >
        <td class="text-end">{{'SUBTOTAL'|translate}}:</td>
        <td class="text-nowrap"
          [innerHTML]="total|currencyConvert:total.currency"></td>
      </tr>
      <tr *ngIf="shippingCost.shipping_costs?.value">
        <td class="text-end border-top">{{'TOTAL'|translate}}:</td>
        <td class="text-nowrap border-top"
          [innerHTML]="grandTotal(shippingCost.shipping_costs?.value, total) |currencyConvert:shippingCost.shipping_costs?.currency"></td>
      </tr>
    </table>
  </ng-container>
</div>

<ng-container *ngIf="!selectedShippingOverview">
  <ng-container *ngFor="let shippingCost of shippingCosts">
    <h3>{{shippingCost.shipping_address?.name}} {{shippingCost.shipping_address?.city}}</h3>
    <table class="table table-striped">
      <tr>
        <td>{{'MIN_ORDER_AMOUNT_NOSHIPPING'|translate}}</td>
        <td
          [innerHTML]="shippingCost.minimum_order_amount?.value|currencyConvert:shippingCost.minimum_order_amount?.currency">
        </td>
      </tr>
      <tr>
        <td>{{'SUBTOTAL_BIKE_PARTS'|translate}}</td>
        <td [innerHTML]="shippingCost.ordered_amount?.value|currencyConvert:shippingCost.ordered_amount?.currency"></td>
      </tr>
      <tr>
        <td>{{'ACCELL_NL_RESTAMOUNT'|translate}}</td>
        <td [innerHTML]="shippingCost.to_order_amount?.value|currencyConvert:shippingCost.to_order_amount?.currency">
        </td>
      </tr>
      <tr>
        <td>{{'SHIPPINGCOST'|translate}}</td>
        <td [innerHTML]="shippingCost.shipping_costs?.value|currencyConvert:shippingCost.shipping_costs?.currency"></td>
      </tr>
    </table>
  </ng-container>
</ng-container>