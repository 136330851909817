import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationService } from '../services/application.service';

@Injectable({
  providedIn: 'root'
})
export class IsCompanyGroupGuard implements CanActivate {
  constructor(
    private router: Router,
    private applicationService: ApplicationService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const selectedCompanyGroup = next.params["cgc"];
      let companyGroups = next.data['companyGroups'] as Array<string>;
      const userCompanyGroups = this.applicationService.getAvailableCompanyGroups();
      if (companyGroups.indexOf(selectedCompanyGroup) >= 0 && userCompanyGroups.includes(selectedCompanyGroup)) {
        return true;
      } else {

        // Check if available in CGC that the user does have and redirect
        const overlappingGroups = userCompanyGroups.filter(group => companyGroups.includes(group) && group !== selectedCompanyGroup);
        if (overlappingGroups.length > 0) {
          let newUrl = window.location.pathname.replace(selectedCompanyGroup, overlappingGroups[0]);
          this.router.navigateByUrl(newUrl);
          return false;
        }
        this.router.navigateByUrl(`${selectedCompanyGroup}/notfound`);
        return false;
      }
  }
}
