<ng-container *ngIf="!showFullText && !article?.warehouse && showStatus">
  <span
    class="statusIcon status d-inline-flex align-items-center"
    [ngClass]="[
      article?.call_dealersupport ? 'border border-warning notice mt-2' : '',
      'status_' + (article?.call_dealersupport ? '3' : article?.stock_status),
      'company_' + article?.company_id
    ]"
    [placement]="tooltipPlacement"
    [ngbTooltip]="!showText ? (article | statusText) : null"
  >
    <span
      class="text"
      *ngIf="
        showText &&
        (!article?.setitem || (article?.setitem && article?.stock_status < 7))
      "
    >
    <span *ngIf="article?.matrix?.options?.length && companyGroupCode === 'ANL'">{{ article?.matrix?.options[0]?.description}} - </span>{{ article | statusText }}</span
    ></span
  >
</ng-container>
<ng-container *ngIf="showFullText && showStatus">
  <div
    class="status_text"
    [ngClass]="[
      'status_' + article?.stock_status,
      'company_' + article?.company_id
    ]"
  >
    {{ article | statusText }}
  </div>
</ng-container>
<span
  class="statusIcon status d-inline-flex align-items-center"
  [ngClass]="['status_' + fastestDelivery?.stock_status]"
  *ngIf="article?.warehouse"
>
  {{ warehouseText }}
</span>
