import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild, SimpleChanges } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ArticleGroup } from "src/app/interfaces/articleGroup";
import { GroupCollection } from "src/app/interfaces/group-collection";
import { MenuLayout } from "src/app/interfaces/menu-layout";
import { MenuItem } from "src/app/interfaces/menuItem";
import { ApplicationService } from "src/app/services/application.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-groups-tree-searchbar",
  templateUrl: "./groups-tree-searchbar.component.html",
  styleUrls: ["./groups-tree-searchbar.component.scss"]
})
export class GroupsTreeSearchbarComponent implements OnInit {
  @Input() groups: MenuItem[] = [];
  @Output()
  groupSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  companyGroup: string;
  selectedGroup: any;
  loading = true;

  constructor(private router: Router, private searchService: SearchService, private applicationService: ApplicationService) {
    this.selectedGroup = new GroupCollection();
  }

  ngOnInit() {
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.searchService.resultsFetchedEmitted$.subscribe((request) => {
      if (request['page'] === 1) {
        this.initializeSelectedGroup();
      }
    });

    window.addEventListener('popstate', (event) => {
      this.initializeSelectedGroup();
    });

    this.router.events.subscribe((data: any) => {
      if (data.url && data instanceof NavigationEnd) {
        if (data.url.includes("home")) {
          this.resetProductGroups();
        }
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['groups']) {
      this.loading = false;
      this.initializeSelectedGroup();
    }
  }

  // Determine which group to highlight and scroll to
  initializeSelectedGroup() {
    const groupsParam = window.location.href.match(/groups=([^&]*)/)?.[1];
    this.resetProductGroups();

    if (groupsParam) {
      let scrollGroup = '';
      const [product_group_codes, article_group_codes, article_sub_group_codes, variation_group_code] = groupsParam.split(',');
      this.groups.forEach(group => {
        if (group.name === product_group_codes) {
          this.selectedGroup["product_group_codes"] = `${group.name}^${group.description}` || null;
        }
        scrollGroup = `pg-${group.name}`;
        if (group.sub_items) {
          group.sub_items.forEach(article_group => {
            if (article_group.name === article_group_codes) {
              this.selectedGroup["article_group_codes"] = `${article_group.name}^${article_group.description}` || null;
              scrollGroup = `ag-${article_group.name}`;
            }
            article_group.sub_items?.forEach(article_sub_group => {
              if (article_sub_group.name === article_sub_group_codes) {
                this.selectedGroup["article_sub_group_codes"] = `${article_sub_group.name}^${article_sub_group.description}` || null;
                scrollGroup = `sg-${article_sub_group.name}`;
              }
              article_sub_group.sub_items?.forEach(article_variation_group => {
                if (article_variation_group.name === variation_group_code) {
                  this.selectedGroup["variation_group_code"] = `${article_variation_group.name}^${article_variation_group.description}` || null;
                  scrollGroup = `vg-${article_variation_group.name}`;
                }
              });
            });
          });
        }
      });

      this.setDropdown();
      this.groupSelected.emit(this.selectedGroup);
    } else {
      this.highLightGroup('')
    }
  }

  resetProductGroups() {
    this.selectedGroup["article_group_codes"] = null;
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["product_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.highLightGroup();
    this.groupSelected.emit(this.selectedGroup);
  }

  selectProductGroup(group, emit = false) {
    this.selectedGroup["article_group_codes"] = null;
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.selectedGroup["product_group_codes"] =
      this.selectedGroup["product_group_codes"] !== group.name
        ? `${group.name}^${group.description}`
        : this.selectedGroup["product_group_codes"];
    if (emit) {
      this.groupSelected.emit(this.selectedGroup);
    }
  }

  selectArticleGroup(articleGroup, emit = false) {
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.selectedGroup["article_group_codes"] =
      this.selectedGroup["article_group_codes"] !== articleGroup.name
        ? `${articleGroup.name}^${articleGroup.description}`
        : this.selectedGroup["article_group_codes"];
    if (emit) {
      this.groupSelected.emit(this.selectedGroup);
    }
  }

  selectArticleSubGroup(articleSubGroup, emit = false) {
    this.selectedGroup["variation_group_code"] = null;
    if (this.selectedGroup["article_sub_group_codes"] !== articleSubGroup.name) {
      this.selectedGroup["article_sub_group_codes"] = `${articleSubGroup.name}^${articleSubGroup.description}`;
      if (emit) {
        this.groupSelected.emit(this.selectedGroup);
      }
    }
  }

  selectArticleVariationGroup(articleVariationGroup, emit = false) {
    if (this.selectedGroup["variation_group_code"] !== articleVariationGroup.name) {
      this.selectedGroup["variation_group_code"] = `${articleVariationGroup.name}^${articleVariationGroup.description}`;
      if (emit) {
        this.groupSelected.emit(this.selectedGroup);
      }
    }
  }

  public setDropdown() {

    // If cgc changed from other refresh data when opening
    if (this.companyGroup !== this.applicationService.getSelectCompanyGroupCode()) {
      this.loading = true;
      this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
      this.applicationService.getMenuItems(false).then((data: MenuLayout) => {
        this.loading = false;
        this.groups = data.browse_groups;
      })
    }

    if (this.selectedGroup["variation_group_code"]) {
      this.highLightGroup(this.selectedGroup["variation_group_code"].split('^')[0], 'vg')
    } else if (this.selectedGroup["article_sub_group_codes"]) {
      this.highLightGroup(this.selectedGroup["article_sub_group_codes"].split('^')[0], 'sg')
    } else if (this.selectedGroup["article_group_codes"]) {
      this.highLightGroup(this.selectedGroup["article_group_codes"].split('^')[0], 'ag')
    } else if (this.selectedGroup["product_group_codes"]) {
      this.highLightGroup(this.selectedGroup["product_group_codes"].split('^')[0], 'pg')
    }
  }

  highLightGroup(id?: string, group?: string) {
    let targetElement = null;
    const selected = document.getElementsByClassName('selected-group');
    while (selected.length > 0) {
      selected[0].classList.remove('selected-group');
    }

    // Select 'app-groups-tree-searchbar' element
    const container = document.getElementById('groups-tree-searchbar') as HTMLElement;

    if (container) {
      if (id) {
        // Select the element you want to scroll to
        targetElement = document.getElementById(`sb-group-${group}-${id}`);
      }

      if (targetElement) {
        targetElement.classList.add('selected-group');
      } else {
        container.scrollTo(0, 0)
      }
    }

    const label = document.querySelector(".selected-group-label");
    const text = document.querySelector(".scroll-text");

    // Check if the label and text elements exist
    if (label && text) {
      // Check if the text is overflowing
      if (text.scrollWidth > label.clientWidth) {
        label.addEventListener("mouseenter", () => {
          text.classList.add("scroll-animation");
        });

        label.addEventListener("mouseleave", () => {
          text.classList.remove("scroll-animation");
        });
      }
    }
  }
}
