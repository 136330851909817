<li class="list-group-item border-0 me-2" (click)="articleSelected(index, $event)" (dblclick)="openModal.emit(article)"
  placement="left" tooltipClass="custom-tooltip">
  <div class="row">
    <div class="col-md-2" (click)="selectArticle.emit($event)">
      <input type="checkbox" class="selectForAdd" [(ngModel)]="article.selected" *ngIf="article.single_article_code">
      <span class="imgContainer justify-content-center" [style.max-width]="(imageSize + 10) + 'px'">
        <app-article-image class="d-inline-block mt-2 w-100 text-center thumbnail" [articleType]="article.article_type"
          [imageUrl]="
      article.thumbs && article.thumbs.length ? article.thumbs[0] : ''
    " [description]="article.description" [productGroup]="article.product_group_codes" [width]="300" [height]="400"
          [classList]="''"></app-article-image>
      </span>
    </div>
    <div class="col-md-6">
      <h5>
        {{article['description']}}
        <span
          *ngIf="(article.stock_status < 3 || article.setitem) && (article.promotion || article.new || article.setitem || article.dropshipment || article.is_lease)"
          class="d-inline-block">
          <app-highlight-badge [article]="article" class="d-flex">
          </app-highlight-badge>
        </span>
      </h5>
      <small [innerHtml]="(article['caption'] ?? '') + ' ' + (article['single_article_code'] ?? '')"></small>
      <small class=""><i class="material-icons" *ngIf="article['selected']">check_circle</i></small>
      <div class="d-block">
        <span *ngIf="article.retail_price">
          <app-retail-price [price]="article.retail_price" [showLabel]="true"></app-retail-price>
        </span>
        <span *ngIf="showGrossPrice && article.gross_price" class="me-1"><br />
          <app-gross-price [price]="article.gross_price" [showLabel]="true"></app-gross-price>
        </span>
        <span *ngIf="article.net_price" class="d-block fw-bold">
          <app-nett-price [price]="article.net_price" [showLabel]="true" [article]="article"></app-nett-price>
        </span>
        <app-stock-status [article]="article" class="position-absolute top-0 end-0" />
      </div>
    </div>
    <!-- <div class="col-md-4 text-end">
      <app-stock-status [showText]="false" *ngIf="article.stock_status < 7" [article]="article">
      </app-stock-status>
    </div> -->
  </div>
</li>
