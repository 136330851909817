import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItem } from '../interfaces/menuItem';
import { PreorderFormSettings } from '../interfaces/admintool/preorder-form-settings';
import { environment } from 'src/environments/environment';
import { AdminMenuItem } from '../interfaces/admintool/admin-menu-item';
import { AdminArticleGroup } from '../interfaces/admintool/admin-article-group';
import { ApplicationService } from './application.service';
import { PreorderSettings } from '../interfaces/settings/preorder-settings';
import { map } from 'rxjs';
import { ReplenishmentSettings } from '../interfaces/replenishment-settings';
import { CreateFilterData } from '../interfaces/admintool/create-filter-data';
import { Synonym } from '../interfaces/admintool/synonym';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient, private applicationService: ApplicationService) {}

  getArticleGroups() {
    return this.http.get(`${environment.apiendpoint}admin/groups`)
  }

  getGroups() {
    return this.http.get(`${environment.apiendpoint}admin/groups`)
  }

  createGroup(
    rank: number,
    description: string,
    parentId: string,
    labels: any,
    classificationCodes: string[]
  ) {
    return this.http.post(`${environment.apiendpoint}admin/groups`, {
      label: description,
      labels: labels,
      rank: rank,
      parent_id: parentId,
      classification_codes: classificationCodes
    });
  }

  updateGroup(item: AdminArticleGroup, languageDescription: any) {
    return this.http.put(`${environment.apiendpoint}admin/groups/${item.id}`, {
      id: item.id,
      label: item.label,
      labels: languageDescription,
      rank: item.rank,
      company_group_code: item.company_group_code,
      parent_id: item.parent_id,
      classification_codes: item.classification_codes
    });
  }

  deleteGroup(item: AdminArticleGroup) {
    return this.http.delete(`${environment.apiendpoint}admin/groups/${item.id}`);
  }

  rankUpGroup(item: AdminArticleGroup) {
    return this.http.put(`${environment.apiendpoint}admin/groups/${item.id}/up`, {});
  }

  rankDownGroup(item: AdminArticleGroup) {
    return this.http.put(`${environment.apiendpoint}admin/groups/${item.id}/down`, {});
  }

  groupFilters(id: string) {
    return this.http.get(`${environment.apiendpoint}admin/specs/groups/${id}/filters`);
  }

  groupSpecs(id: string) {
    return this.http.get(`${environment.apiendpoint}admin/groups/${id}/specs`);
  }

  getSpec(groupId: string, specCode: string) {
    return this.http.get(`${environment.apiendpoint}admin/groups/${groupId}/specs/${specCode}`);
  }

  createFilter(filter: CreateFilterData) {
    return this.http.post(`${environment.apiendpoint}admin/specs/filters`, filter);
  }

  updateFilter(filter: CreateFilterData) {
    return this.http.put(`${environment.apiendpoint}admin/specs/filters`, filter);
  }

  getFilters(filterId: number) {
    return this.http.get(`${environment.apiendpoint}admin/specs/filters/${filterId}`);
  }

  deleteFilter(id: string) {
    return this.http.delete(`${environment.apiendpoint}admin/specs/filters/${id}`);
  }

  // Synonyms
  getSynonyms(languageCode: string) {
    return this.http.get(`${environment.apiendpoint}synonyms/language/${languageCode}`);
  }

  addSynonym(synonym: Synonym, languageCode: string) {
    if (synonym.id) {
      return this.http.put(`${environment.apiendpoint}synonyms`, synonym);
    } else {
      return this.http.post(`${environment.apiendpoint}synonyms`, synonym);
    }
  }

  deleteSynonym(synonym: Synonym) {
    return this.http.delete(`${environment.apiendpoint}synonyms/${synonym.id}`);
  }

  savePreorder(preorderFormSettings: PreorderSettings) {
    return this.http.put(
      `${environment.apiendpoint}companygroups/${this.applicationService.getSelectCompanyGroupCode()}/preordersettings/`,
      preorderFormSettings
    );
  }

  preorderSettings() {
    return this.http.get(`${environment.apiendpoint}companygroups/${this.applicationService.getSelectCompanyGroupCode()}/preordersettings/`);
  }
  menuItems() {
    return this.http.get(`http://localhost:3001/menu`);
  }

  updateMenuItem(item: AdminMenuItem) {
    return this.http.post('http://localhost:3001/menu-item', item);
  }
  xlSettings() {
    return this.http.get(`${environment.apiendpoint}replenish/settings`);
  }

  saveXl(data: ReplenishmentSettings) {
    return this.http.put(`${environment.apiendpoint}replenish/settings`,data);
  }

  marketplaceSettings() {
    return this.http.get(`${environment.apiendpoint}companygroups/${this.applicationService.getSelectCompanyGroupCode()}/marketplacesettings/`);
  }

  saveMarketplaceSettings(year: string) {
    return this.http.put(`${environment.apiendpoint}companygroups/${this.applicationService.getSelectCompanyGroupCode()}/marketplacesettings/`, {
      company_group_code: this.applicationService.getSelectCompanyGroupCode(),
      block_model_year: year
    });
  }

  getIndexStatus() {
    return this.http.get(`${environment.publicapiendpoint}info/indices/accentry`);
  }

  //SPECS
  specsGreylist() {
    return this.http.get(`${environment.apiendpoint}admin/specs/blacklist`);
  }

  addSpecToBlacklist(specCode: string) {
    return this.http.post(`${environment.apiendpoint}admin/specs/blacklist?c=${specCode}`, {});
  }

  removeSpecFromBlacklist(specCode: string) {
    return this.http.delete(`${environment.apiendpoint}admin/specs/blacklist?c=${specCode}`, {});
  }
}
