import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Article, StockPosition } from "src/app/interfaces/article";
import { Price } from "src/app/interfaces/price";
import { ApplicationService } from "src/app/services/application.service";
import { SettingsService } from "src/app/services/settings.service";
import * as _ from "underscore";

@Component({
  selector: "app-stock-status",
  templateUrl: "./stock-status.component.html",
  styleUrls: ["./stock-status.component.scss"],
})
export class StockStatusComponent implements OnInit {
  @Input() article: Article;
  @Input() showText: boolean;
  @Input() showFullText: boolean;
  @Input() tooltipPlacement: string = 'left';
  showStatus: boolean;
  warehouseText: string = "";
  companyGroupCode: string = "";
  fastestDelivery: StockPosition;

  constructor(
    private applicationService: ApplicationService,
    private settingsService: SettingsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getOtherWarehouseStockStatus();
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    if (this.article?.setitem) {
      this.showStatus = false;
    } else {
      this.showStatus =
      (this.settingsService.b2bSettings?.ordering?.show_additional_week_status_text) ||
      typeof this.article?.gross_price === "undefined" ||
      (this.article?.gross_price && this.article?.gross_price.value
        ? true
        : false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["article"]) {
      this.getOtherWarehouseStockStatus();
    }
  }

  // Determine if an external warehouse delivery is possible and faster
  getOtherWarehouseStockStatus() {
    if (this.article?.stock_positions && this.article?.stock_positions.length) {
      let fasterDeliveries: StockPosition[] = [];
      const stockPositions = [...this.article.stock_positions];
      stockPositions.forEach((s: StockPosition) => {
        if (s.stock_status <= this.article.stock_status) {
          if (
            s.stock_week < this.article.stock_week &&
            s.stock_year <= this.article.stock_year
          ) {
            s.warehouse_stock_date_formatted = `${s.stock_year}${s.stock_week}`;
            fasterDeliveries.push(s);
          }
        }
      });
      if (fasterDeliveries.length) {
        fasterDeliveries = _.sortBy(
          fasterDeliveries,
          "warehouse_stock_date_formatted"
        );
      }
      const currentWeek = moment().isoWeek();
      if (fasterDeliveries.length) {
        const fastestDelivery = fasterDeliveries[0];
        this.warehouseText = `${this.translateService.instant(
          "EXTERNAL_WAREHOUSE_DELIVERY"
        )}. ${this.translateService.instant("DELIVERY_IN")} `;
        if (
          fastestDelivery.stock_week === currentWeek ||
          fastestDelivery.stock_year < this.article.stock_year
        ) {
          this.warehouseText += `± 7 ${this.translateService.instant("DAYS")}`;
        } else {
          this.warehouseText += `± ${
            (fastestDelivery.stock_week - currentWeek) * 7
          } ${this.translateService.instant(
            "DAYS"
          )} (${this.translateService.instant("WEEK")} ${
            fastestDelivery.stock_week
          })`;
        }
        this.article.warehouse = fastestDelivery.warehouse;
        this.article.delivery_week = String(fastestDelivery.stock_week);
        this.article.delivery_year = String(fastestDelivery.stock_year);
        this.article.stock_status = fastestDelivery.stock_status;
        this.fastestDelivery = fastestDelivery;
      }
    }
  }
}
