import {
  Component,
  OnInit,
  Input,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApplicationService } from 'src/app/services/application.service';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { BasketService } from 'src/app/services/basket.service';
import { MenuItem } from 'src/app/interfaces/menuItem';
import { MenuLayout } from 'src/app/interfaces/menu-layout';
import { Location } from '@angular/common';
import { HelpdeskService } from 'src/app/services/helpdesk.service';
import { User } from 'src/app/interfaces/user';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { SearchService } from 'src/app/services/search.service';
import { AccountService } from 'src/app/services/account.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { HelperService } from 'src/app/services/helper.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-brand-selector',
  templateUrl: './brand-selector.component.html',
  styleUrls: ['./brand-selector.component.scss'],
})
export class BrandSelectorComponent implements OnInit {
  @Output() brandClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedCompanyGroup: string;
  companies: number[];
  @Input() test: boolean = false;
  showCompanyDropdown: boolean = false;
  availableCompanyGroups: any = [];
  companyGroupCode = '';
  companyGroupsSettings: CompanyGroup;
  initialCompanyGroupCode = '';
  hasEWBP: boolean = false;
  brands: any = {};

  constructor(
    public applicationService: ApplicationService,
    private basketService: BasketService,
    private location: Location,
    private router: Router,
    private chatService: ChatService,
    private searchService: SearchService,
    private helperService: HelperService,
    private accountService: AccountService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    // Reload companygroup settings if needed
    const promise = new Promise((resolve) => {
      if (sessionStorage.getItem('newSettings')) {
        resolve(true);
      } else {
        this.accountService
          .getAvailableCompanyGroups()
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.applicationService.saveCompanyGroups(apiResponse.result);
              sessionStorage.setItem('newSettings', '1');
              resolve(true);
            }
          });
      }
    });
    promise.then(() => {
      this.init();
    });
  }

  init() {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(
        this.companyGroupCode
      ) as CompanyGroup;
    this.getMenuItems();

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      (response) => {
        this.companyGroupCode =
          this.applicationService.getSelectCompanyGroupCode();
        this.getMenuItems(true);
      }
    );

    this.brands = this.applicationService.getAllGroupBrands();
  }

  getMenuItems(clear = false) {
    this.initialCompanyGroupCode =
      this.applicationService.getSelectCompanyGroupCode();
    this.companyGroupCode = this.initialCompanyGroupCode;
    this.availableCompanyGroups = this.applicationService.getSetting(
      'companyGroups',
      '[]'
    );
  }

  setCompanyGroup(event, code, redirect = true) {
    this.applicationService.saveSelectedCompanyGroupCode(code);
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(code) as CompanyGroup;
    this.companyGroupCode = code;
    this.basketService.emitChange('');
    this.location.go(code);
    this.getMenuItems();
    sessionStorage.removeItem('news-' + this.companyGroupCode);
    const homepage = this.applicationService.isPartnerPortal()
      ? '/partnerPortal'
      : '/home';
    if (redirect) {
      this.router.navigateByUrl(`${code}${homepage}`);
    }
    this.chatService.toggleChat(this.settingsService.hasChat(this.applicationService.getSelectedLanguage())); // Enable chat for ANL only

    if (event) {
      event.preventDefault();
    }
    return false;
  }

  gotoSearch(companyGroup: string, brand: string, event, type: string) {
    this.searchService.setBrandAndArticleType([brand], type);
    const url = `/${companyGroup}/search#filter&brands=${brand}&article_type=${type}`;

    if (window.location.href.includes('/search')) {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
    this.setCompanyGroup(event, companyGroup, false);
    this.showCompanyDropdown = false;
    this.searchService.emitChange('');
    this.brandClicked.next(true);
    event.preventDefault();
    return false;
  }
}
