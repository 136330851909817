export enum AvailableArticleTypes {
  "P" = "P",
  "FP" = "FP",
  "FR1" = "FR1",
  "FP1" = "FP1",
  "PA1" = "PA1",
  "PA2" = "PA2",
  "OT1" = "OT1",
  "F" = "F",
  "Bicycles" = "Bicycles",
  "Parts" = "Parts"
}

export class ArticleType {
  public AvailableArticleTypes = AvailableArticleTypes;
}
